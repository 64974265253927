import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import {useHistory} from "react-router-dom"
import {
  Typography,
  Container,
  Button
} from "@material-ui/core"
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";


const styles = {
  root: {
    background: "white"
  },
  input: {
    color: "white"
  }
};

const Input = () => {
  const history = useHistory()

  const handleSubmitSearch = (event) => {
    event.preventDefault()
    const search = event.target.search.value
    if (!search) return
    history.push(`/search/${search}`)
  }
  
  const handleSubmitUrl = (event) => {
    event.preventDefault()
    const url = event.target.url.value
    if (!url) return
    const id = processId(url)
    history.push(`/${id}`)
  }

  const processId = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0]
  }


  return (
    <Container maxWidth="xl" style={{paddingLeft: 0, paddingRight: 0, paddingBottom: 15}}>
      <Toolbar variant="dense" disableGutters={true}>
        <form 
          noValidate 
          autoComplete="off" 
          onSubmit={handleSubmitSearch}
        >
          <TextField 
            label="Search"
            variant="outlined"
            name="search"
            style={{width: 450}}
          />
        </form>

        <Typography variant="body1" style={{marginRight: 10, marginLeft: 10}}>
          or
        </Typography>

        <form 
          noValidate autoComplete="off" 
          onSubmit={handleSubmitUrl}
        >
          <TextField 
            label="Enter Video URL"
            variant="outlined" 
            name="url"
            style={{width: 450}}
          />
        </form>
      </Toolbar>
    </Container>
  );
}

export default Input
