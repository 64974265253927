import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export default function SimpleMenu({ playbackSpeed, setPlaybackSpeed }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (speed) => {
    setPlaybackSpeed(speed)
    setAnchorEl(null);
    
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        Speed
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose(0.25)}>0.25</MenuItem>
        <MenuItem onClick={() => handleClose(0.5)}>0.5</MenuItem>
        <MenuItem onClick={() => handleClose(0.75)}>0.75</MenuItem>
        <MenuItem onClick={() => handleClose(1)}>1</MenuItem>
        <MenuItem onClick={() => handleClose(1.25)}>1.25</MenuItem>
        <MenuItem onClick={() => handleClose(1.5)}>1.5</MenuItem>
        <MenuItem onClick={() => handleClose(1.75)}>1.75</MenuItem>
        <MenuItem onClick={() => handleClose(2)}>2</MenuItem>
      </Menu>
    </div>
  );
}