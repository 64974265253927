import React, {useEffect} from "react"
import Grid from '@material-ui/core/Grid';
import { Typography } from "@material-ui/core";
import Container from '@material-ui/core/Container';
import { useSelector, useDispatch } from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom"
import {setSearch} from "../reducers/searchReducer"
import he from "he"
import tempdata from "../tempdata"

const Search = () => {

  const searchParams = useParams().searchParams
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => { 
    dispatch(setSearch(searchParams))
  }, [dispatch, searchParams])

  const searchResults = useSelector(state => state.search)

  const processId = (url) => {
    url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
    return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0]
  }

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {
            // tempdata.map(result => {
            searchResults.map(result => {
              const video = result.snippet
              const videoUrl = `https://www.youtube.com/watch?v=${result.id.videoId}`
              if (result.id.kind === "youtube#video") {
                return (
                  <React.Fragment key={result.id.videoId}>
                    <Link to={`/${result.id.videoId}`} >
                      <Grid item xs={6} style={{marginTop: 5, marginBottom: 5 }}>
                        <img src={video.thumbnails.medium.url} alt={video.title} width="320" height="180" />
                      </Grid>
                    </Link>
                    <Grid item xs={6} style={{paddingLeft: 20}}>
                      <Link to={`/${result.id.videoId}`} style={{ 
                        textDecoration: "none", 
                      }}>
                        <Typography variant="body1" style={{color: "#FFF", fontSize: 17}}>
                          {he.decode(video.title)}
                        </Typography>
  
                        <Typography 
                        variant="caption"
                        style={{color: "rgba(255, 255, 255, 0.6)"}}
                        >
                          {he.decode(result.snippet.channelTitle)}
                        </Typography>
  
                        <Typography
                          variant="body2"
                          style={{color: "rgba(255, 255, 255, 0.6)"}}  
                        >
                          {he.decode(video.description)}
                        </Typography>
                      </Link>
                    </Grid>
                  </React.Fragment>
                )
              } else return null
            })
          }
        </Grid>
      </Container>
    </>
  )
}

export default Search
