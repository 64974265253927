import React from "react"
import "./App.css"
import { Container } from "@material-ui/core"
import Search from "./components/Search"
import MainDisplay from "./components/MainDisplay"
import Input from "./components/Input"
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom"

const App = () => {
  return (
    <Container maxWidth="xl">
      <Router>
        <Input />
        <Switch>
          <Route path="/search/:searchParams">
            <Search />
          </Route>
          <Route path="/:id">
            <MainDisplay />
          </Route>
          <Route>
            <Redirect to="/GESh9Dg8oJE?t=388" />
          </Route>
        </Switch>
      </Router>
    </Container>
  )
}

export default App
