import React from "react"
import Slider from "./Slider.js"
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import Container from '@material-ui/core/Container';
import QualityMenu from "./QualityMenu"
import Grid from "@material-ui/core/Grid"
import {Tooltip} from "@material-ui/core"
import FullscreenIcon from '@material-ui/icons/Fullscreen';


const Controls = ({ togglePlaying, playing, navigateVideo, playbackSpeed, setPlaybackSpeed, setVolume }) => {
  return (
    <>
      <Container>
        <Grid container 
          spacing={2} 
          alignItems="center" 
          justify="center" 
          style={{paddingTop: 30}}
        >
          <Grid item sm={0}>
          </Grid>
          <Grid item md={1} sm={12}>
            <QualityMenu playbackSpeed={playbackSpeed} setPlaybackSpeed={setPlaybackSpeed} />
          </Grid>

          <Grid item md={7} sm={12} style={{display: "flex", justifyContent: "space-around"}}>
              <div>
                <Button 
                variant="contained" color="primary" 
                onClick={() => navigateVideo(-300)}
                >
                  5m
                </Button>
              </div>
              <div>
                <Button variant="contained" color="primary"
                onClick={() => navigateVideo(-60)}
                >
                  1m
                </Button>
              </div>
              <div>
                <Button variant="contained" color="primary" 
                onClick={() => navigateVideo(-5)}>
                  5s
                </Button>
              </div>

              <div>
                <Button 
                  style={{backgroundColor: "red", color: "white"}}
                  variant="contained" 
                  onClick={togglePlaying}
                >
                  {playing
                    ? <PauseIcon />
                    : <PlayArrowIcon />
                  }
                </Button>
              </div>

              <div>
                <Button
                variant="contained" color="primary"
                onClick={() => navigateVideo(5)}
                >
                  5s
                </Button>
              </div>
              <div>
                <Button 
                variant="contained" color="primary" 
                onClick={() => navigateVideo(60)}
                >
                  1m
                </Button>
              </div>
              <div>
                <Button
                variant="contained" color="primary"
                onClick={() => navigateVideo(300)}
                >
                  5m
                </Button>
              </div>
          </Grid>

          <Grid item md={3} sm={12} style={{display: "flex", justifyContent: "space-around"}}>
            <div>
            <Tooltip title="Please double click on video to fullscreen">
                <FullscreenIcon />
              </Tooltip>
            </div>
            <div>
              <Slider variant="contained" color="primary" setVolume={setVolume} />
            </div>
          </Grid>
        </Grid>
          

      </Container>


    </>
  )
}

export default Controls