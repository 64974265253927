import React, {useState, useRef } from "react"
import ReactPlayer from 'react-player'
import Controls from "./Controls"
import Container from '@material-ui/core/Container';
import {useParams} from "react-router-dom"

const MainDisplay = ({ url }) => {
  const [ playbackSpeed, setPlaybackSpeed ] = useState(1)
  const [ volume, setVolume ] = useState(0.5)
  const [ playing, setPlaying ] = useState(false)

  const id = useParams().id
  const videoPlayer = useRef(null)


  const navigateVideo = (time) => {
    const currentTime = videoPlayer.current.getCurrentTime()
    videoPlayer.current.seekTo(currentTime + time)
  }

  const togglePlaying = () => {setPlaying(!playing)}
  
  const onPlay = () => setPlaying(true)
  const onPause = () => setPlaying(false)

  return (
    <div>
      <div className="player-wrapper">
        <ReactPlayer 
          url={`https://www.youtube.com/watch?v=${id}`}
          playbackRate={playbackSpeed}
          volume={volume}
          playing={playing}
          width="100%"
          height="100%"
          onPlay={onPlay}
          onPause={onPause}
          ref={videoPlayer}
          className="react-player"
        />
      </div>
      <Controls 
        togglePlaying={togglePlaying} 
        playing={playing} 
        navigateVideo={navigateVideo} 
        playbackSpeed={playbackSpeed} 
        setPlaybackSpeed={setPlaybackSpeed} 
        setVolume={setVolume} 
      />
    </div>
  )
}

export default MainDisplay