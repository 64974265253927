import axios from "axios"

const searchYT = async (searchParams) => {
  const youtubeUrl = "https://youtube.googleapis.com/youtube/v3/search?"
  const key = process.env.REACT_APP_YOUTUBE

  const ytResponse = await axios.get(
    `${youtubeUrl}part=snippet&maxResults=25&q=${searchParams}&key=${key}`
  )

  const results = ytResponse.data.items
  return results
}

export default {
  searchYT,
}
