import searchService from "../services/searchService"

const searchReducer = (state = [], action) => {
	switch (action.type) {
		case "SET_SEARCH":
			return action.data
		default:
			return state
	}
}

export const setSearch = (searchParam) => {
	return async dispatch => {
		const results = await searchService.searchYT(searchParam)
		dispatch({
			type: "SET_SEARCH",
			data: results
		})
	}
}

export default searchReducer